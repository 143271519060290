

var endpoint = '/dash'
export default class ProcedureService {

  constructor(axios){
    this.axios = axios
  }

  daySummary(params) {
    console.log(" ||||",params)
    return this.axios.get(`${endpoint}/daysummary`, { params })
  }

}
