<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DashboardService from "@/services/dashboard-service.js"
import {
  rolesMethods,
} from "@/state/helpers";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Pronto Clínica Sphera"
        },
        {
          text: "Painel",
          active: true
        }
      ],
      roles: [],
      groupedData: {},
      today: new Date(),
      weekDays: [],
      selectedDay: null,
      months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    

    };
  },
  methods: {
    ...rolesMethods,
    groupData(data) {
      this.groupedData = data.reduce((grouped, item) => {
        if (!grouped[item.unitName]) {
          grouped[item.unitName] = [];
        }
        grouped[item.unitName].push(item);
        return grouped;
      }, {});
    },
    calculateWeekDays() {
      const startOfWeek = this.getStartOfWeek(this.today);
      this.weekDays = Array.from({ length: 7 }).map((_, index) => {
        const day = new Date(startOfWeek);
        day.setDate(day.getDate() + index);
        return day;
      });
    },
    mudarSemana(direction) {
      this.today.setDate(this.today.getDate() + direction * 7);
      this.calculateWeekDays();
    },
    getStartOfWeek(date) {
      const day = new Date(date);
      const diff = day.getDate() - day.getDay() + (day.getDay() === 0 ? -6 : 1); // Ajusta para a semana começar na segunda
      return new Date(day.setDate(diff));
    },
    isToday(day) {
      return day.toDateString() === this.today.toDateString();
    },
    selectDay(day) {
      this.selectedDay = day;
      this.getDaySummary()
    },
    formatDate(date) {
      return date.toISOString().split('T')[0]; // Formata a data como 'YYYY-MM-DD'
    },
    getDayName(date) {
      return date.toLocaleDateString('pt-BR', { weekday: 'short' });
    },

    getDaySummary(){

      this.dashboardService.daySummary({ date: this.formatDate(this.selectedDay)}).then((res, err) => {

        if(err) {
          console.log(err)
        }
        this.groupData(res.data)

        });
    }

    

  },
  created() {
    this.selectedDay = new Date(this.today);
    
    this.calculateWeekDays();
    
    this.dashboardService = new DashboardService(this.$http);

    this.getDaySummary()

    


    this.getRoles({http:this.$http}).then((res, err) => {
    
      if (err){
           
           this.$toast.error('Erro ao salvar', {
              position: 'top',
              duration: 4000,
            })
        } 
      this.roles = res;
    });


  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
    <ul class="weekdays">
      <button @click="mudarSemana(-1)">← Semana Anterior</button>
      <li v-for="(day, index) in weekDays" :key="index" 
          :class="{  'selected': selectedDay.toDateString() === day.toDateString() }" 
          @click="selectDay(day)">{{ getDayName(day) }} <br>
        {{ day.getDate() }} {{ months[day.getMonth()] }}
      </li>
      <button @click="mudarSemana(1)">Próxima Semana →</button>
    </ul>
  
      
    </div>
    <div class="row">
      
    <div class="col-lg-3"  v-for="(records, unitName) in groupedData" :key="unitName">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title">{{ unitName }} - {{ selectedDay.getDate() }} {{ months[selectedDay.getMonth()] }} {{ selectedDay.getFullYear() }} </h3>
          <div class="table-responsive">
          <table class="table mb-0" >
            <thead>
              <tr>
                <th>Status</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in records" :key="record.status">
                <td>{{ record.status }}</td>
                <td>{{ record.qtt }}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
        </div> 
    </div>
    <div class="row">
      
    </div>
    <div class="row">
      {{roles}}
    </div>
  </Layout>
</template>

<style >
  .weekdays {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.weekdays li {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: lightgrey;
}

.weekdays {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.weekdays li {
  margin: 0 10px;
}

.weekdays li.today {
  font-weight: bold;
  background-color: lightblue;
}

.weekdays li.selected {
  background-color: lightgreen;
}


</style>